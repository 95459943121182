<script>
import {
    ArrowUpIcon,
    ArrowRightIcon,
    MapPinIcon,
    UserCheckIcon,
    MonitorIcon,
    BookIcon,
    ClockIcon,
    DollarSignIcon,
    BriefcaseIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar0";

import Footer from "@/components/footer";
import store from "@/store/store";



/**
 * Page-job-detail component
 */
export default {
    data() {
        return {
          input2sql: {
            job_id: 1,
            user_id:1,
          },
          job: {job_id: '1',},
          job_createdDate: ''
        }
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon,
        MapPinIcon,
        UserCheckIcon,
        MonitorIcon,
        BookIcon,
        ClockIcon,
        DollarSignIcon,
        BriefcaseIcon
    },

  mounted() {
    this.getJobDetails();

  },

  methods: {

    toJobApplication() {
      let route = this.$hostnamefront + '/vacancy-apply/'+this.$route.params.vacancy_id;
      console.log(route);
      window.open(route, '_blank');
    },

    getJobDetails() {

      this.input2sql.user_id = store.state.user_id;
      this.input2sql.vacancy_id = this.$route.params.vacancy_id;


      console.log('inside getJobDetails ')
      console.log(this.input2sql)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/job-description/get?access_token=' + store.state.token,
        data: this.input2sql,
      }).then((res) => {
        this.job = res.data.jobDescription;
        this.job_createdDate = res.data.date_from;
        console.log('resume')
        console.log(res.data);
      });


    },

  }

}
</script>

<template>

<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <!--<img src="images/job/Gradle.svg" class="avatar avatar-small" alt="">-->
                        <!--<h4 class="title mt-4 mb-3"> {{job.job_title}} </h4>-->
                      <h1 class="mdi mdi-briefcase-outline"> </h1>
                      <h1 class="title mt-1 mb-1"> {{job.job_title}} </h1>

                     <!-- <h1 class="title mt-4 mb-3">   </h1>-->
                        <p class="para-desc mx-auto text-muted" v-html="job.job_description"></p>
                        <ul class="list-unstyled">
                        <!--    <li class="list-inline-item text-primary mr-3"><i class="mdi mdi-map-marker text-warning mr-2"></i>{{job.location}}</li>
                            <li class="list-inline-item text-primary"><i class="mdi mdi-office-building text-warning mr-2"></i>{{ job.company_name }}</li> -->
                          <li class="list-inline-item text-primary mr-3"><i class="mdi mdi-map-marker mr-2"></i>{{job.job_city}}</li>
                          <br>
                          <li class="list-inline-item text-primary mr-3"><i class="mdi mdi-office-building mr-2"></i>{{job.job_company}}</li>

                        </ul>

                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Job Detail Start -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-5 col-12">
                    <div class="card sidebar sticky-bar rounded shadow border-0">
                        <div class="card-body widget border-bottom">
                            <h5 class="mb-0">Информация</h5>
                        </div>

                        <div class="card-body">
                            <div class="media widget align-items-center">
                                <user-check-icon class="fea icon-ex-md mr-3"></user-check-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Тип устройства</h6>
                                    <p class="text-primary mb-0">{{job.job_type}}</p>
                                </div>
                            </div>

                            <div class="media widget align-items-center mt-3">
                                <map-pin-icon class="fea icon-ex-md mr-3"></map-pin-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Локация</h6>
                                    <p class="text-primary mb-0">{{job.job_city}}</p>
                                </div>
                            </div>

                            <div class="media widget align-items-center mt-3">
                                <monitor-icon class="fea icon-ex-md mr-3"></monitor-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Должность</h6>
                                    <p class="text-primary mb-0">{{job.job_title}}</p>
                                </div>
                            </div>
                            <!--
                            <div class="media widget align-items-center mt-3">
                                <briefcase-icon class="fea icon-ex-md mr-3"></briefcase-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Experience:</h6>
                                    <p class="text-primary mb-0">+2 Year</p>
                                </div>
                            </div>

                            <div class="media widget align-items-center mt-3">
                                <book-icon class="fea icon-ex-md mr-3"></book-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Qualifications:</h6>
                                    <p class="text-primary mb-0">MSCIT</p>
                                </div>
                            </div>

                            <div class="media widget align-items-center mt-3">
                                <dollar-sign-icon class="fea icon-ex-md mr-3"></dollar-sign-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Salary:</h6>
                                    <p class="text-primary mb-0">+50k to 70k</p>
                                </div>
                            </div>
                              -->
                            <div class="media widget align-items-center mt-3">
                                <clock-icon class="fea icon-ex-md mr-3"></clock-icon>
                                <div class="media-body">
                                    <h6 class="widget-title">Дата публикации</h6>
                                    <p class="text-primary mb-0 mb-0">{{job_createdDate}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="ml-lg-4">
                        <h5>Описание вакансии: </h5>
                        <span v-html="job.description"></span>
                        <div class="mt-4">
                            <h4  class="btn btn-outline-primary" @click="toJobApplication"> Отклинкуться <i class="mdi mdi-send"></i></h4>

                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Job Detail End -->
    <!--end section-->
   <Footer />

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
